import { useState, useEffect } from "react";
import { Form, Input, Button, Select, Checkbox, message } from "antd";
import { useGetIdentity } from "@refinedev/core";
import config from "config/config";

const API_BASE_URL = config.API_BASE_URL;

export const DeleteWebsite = ({ onSuccess, onClose, record }: any) => {
    const [loading, setLoading] = useState(false);
    const config = {
        widget_configs: {
            wid_status: 'active',
            btn_color: '#018e86',
            primary_color: '#018e86',
            secondary_color: '#d0eeed',
            headings_color: '#fff',
            text_color: '#000',
            wid_size: 'medium',
            wid_location: 'bottomRight',
        },
        profile_configs: {
            vision_impaired_profile: "inactive",
            blind_profile: "inactive",
            cognitive_impaired_profile: "inactive",
            dyslexic_profile: "inactive",
            adhd_profile: "inactive",
            motor_impaired_profile: "inactive",
        },
        feature_configs: {
            screen_reader_status: "inactive",
            voice_nav_status: "inactive",
            dark_contrast_status: "inactive",
            high_contrast_status: "inactive",
            low_contrast_status: "inactive",
            monochrome_status: "inactive",
            high_saturation_status: "inactive",
            low_saturation_status: "inactive",
            font_size_status: "inactive",
            dyslexia_font_status: "inactive",
            left_align_status: "inactive",
            right_align_status: "inactive",
            center_align_status: "inactive",
            justify_align_status: "inactive",
            line_height_status: "inactive",
            text_spacing_status: "inactive",
            highlight_links_status: "inactive",
            highlight_headings_status: "inactive",
            pause_animations_status: "inactive",
            hide_images_status: "inactive",
            white_cursor_status: "inactive",
            black_cursor_status: "inactive",
            keyboard_nav_status: "inactive",
            highlight_hover_status: "inactive",
            reading_mask_status: "inactive",
            reading_guide_status: "inactive",
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("wafy-access-token");
            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/deleteSite?url=${record.url}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ config: config })
                }
            );
            if (response.ok) {
                message.success('Website deleted successfully');
                setLoading(false);
                onSuccess();
                onClose();
            } else {
                setLoading(false);
                onClose();
                message.error('Error occurred while deleting site');
            }
        } catch (error) {
            message.error('Error occurred while deleting site');
            setLoading(false);
        }
    };

    return (
        <div style={{ borderRadius: '15px', width: '100%', backgroundColor: '#fcfcfc', padding: '20px' }}>
            <Form
                onFinish={handleDelete}
                style={{ marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}
            >

                <p>Are you sure you want to delete this website?</p>

                {/* Submit Button */}
                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        <Button onClick={() => onClose()}>
                            Close
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Delete
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};
