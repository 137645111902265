import React, { useState, useEffect } from 'react';
import { Typography, Table, Button, Space, Modal, message } from 'antd';
import { PlusOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { useGetIdentity } from "@refinedev/core";
import config from "config/config";
import { AddSite } from './add-site';
import { RenewSubscription } from './renew-subscription';
import { DeleteWebsite } from './delete-site';

const { Text } = Typography;
const API_BASE_URL = config.API_BASE_URL;

export const MySites = () => {
    const { data: user, refetch } = useGetIdentity<{
        user_websites: { url: string, added_at: Date, expiry_at: Date, license_type: string, license_status: String, license_price: { amount: Number, currency: String }, widget_status: string }[];
    }>();

    useEffect(() => {
        document.title = "My Websites | WebAccessify";
    }, []);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [openRenewPlanModal, setOpenRenewPlanModal] = useState(false);
    const [openDeleteWebsiteModal, setOpenDeleteWebsiteModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);

    const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showRenewPlanModal = (record: any) => {
        setOpenRenewPlanModal(true);
        setSelectedRecord(record);
    };

    const handleCloseRenewPlanModal = () => {
        setOpenRenewPlanModal(false);
    };

    const showDeleteWebsiteModal = (record: any) => {
        setOpenDeleteWebsiteModal(true);
        setSelectedRecord(record);
    };

    const cancelDeleteWebsiteModal = (record: any) => {
        setOpenDeleteWebsiteModal(false);
    };


    const columns = [
        {
            title: 'Website',
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: 'Added At',
            dataIndex: 'added_at',
            key: 'added_at',
            render: (added_at: Date) => <Text>{new Date(added_at).toLocaleDateString()}</Text>,
        },
        {
            title: 'License',
            dataIndex: 'license_type',
            key: 'license_type',
            render: (license_type: string) => <Text>{license_type === "free" ? 'Free Trial' : capitalize(license_type)}</Text>
        },
        {
            title: 'Status',
            dataIndex: 'license_status',
            key: 'license_status',
            render: (license_status: string) => <Text>{capitalize(license_status)}</Text>
        },
        {
            title: 'Price',
            dataIndex: 'license_price',
            key: 'license_price',
            render: (license_price: { amount: number; currency: string }) => (
                <Text>{license_price ? `${license_price.amount} ${license_price.currency}` : '0 USD'}</Text>
            )
        },
        {
            title: 'Expires On',
            dataIndex: 'expiry_at',
            key: 'expiry_at',
            render: (expiry_at: Date) => <Text>{expiry_at ? new Date(expiry_at).toLocaleDateString() : '---'}</Text>,
        },
        {
            title: 'Widget',
            dataIndex: 'widget_status',
            key: 'widget_status',
            render: (widget_status: string) => <Text>{capitalize(widget_status)}</Text>
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_: any, record: any, license_type: any) => (
                <Space size="middle">
                    <Button
                        icon={<ReloadOutlined />}
                        type="primary"
                        onClick={() => showRenewPlanModal(record)}
                    >
                        {license_type === "free" ? 'Upgrade' : 'Renew'} Subscription
                    </Button>
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => showDeleteWebsiteModal(record)}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    const data = user?.user_websites?.map((website, index) => ({
        key: index,
        url: website.url,
        added_at: website.added_at,
        expiry_at: website.expiry_at,
        license_type: website.license_type,
        license_status: website.license_status,
        license_price: website.license_price,
        widget_status: website.widget_status
    })) || [];


    return (
        <div>
            <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography.Title level={4}>My Websites</Typography.Title>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()} style={{ marginBottom: '10px' }}>
                    Add Site
                </Button>
            </Space>

            <Table columns={columns} dataSource={data} scroll={{ x: true }} />

            <Modal
                title={"Add Website"}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose={true}
            >
                <AddSite
                    onSuccess={refetch}
                    onClose={handleCancel}
                />
            </Modal>

            <Modal
                title={selectedRecord?.license_type === "free" ? "Upgrade Subscription" : "Renew Subscription"}
                open={openRenewPlanModal}
                onCancel={handleCloseRenewPlanModal}
                footer={null}
                destroyOnClose={true}
            >
                <RenewSubscription
                    onSuccess={refetch}
                    onClose={handleCloseRenewPlanModal}
                    record={selectedRecord}
                />
            </Modal>

            <Modal
                title={"Delete Website"}
                open={openDeleteWebsiteModal}
                onCancel={cancelDeleteWebsiteModal}
                footer={null}
                destroyOnClose={true}
            >
                <DeleteWebsite
                    onSuccess={refetch}
                    onClose={cancelDeleteWebsiteModal}
                    record={selectedRecord}
                />
            </Modal>
        </div>
    );
};