import React, { useState, useEffect } from 'react';
import { Typography, Table, Button, Space, Modal, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useGetIdentity } from "@refinedev/core";
import config from "config/config";
import { AddSubscription } from './add-subscriptions';
import { render } from 'react-dom';

const { Text } = Typography;
const API_BASE_URL = config.API_BASE_URL;

export const Subscriptions = () => {
    const { data: user, refetch } = useGetIdentity<{
        plan_requests: { 
            website: string, 
            requested_at: Date, 
            accepted_at: Date, 
            requested_plan: { price_currency: string, name: string, status: string, actual_price: number, discounted_price: number, promo: string, promo_percentage: number },
            current_plan: { name: string, status: string }
         }[];
    }>();

    useEffect(() => {
        document.title = "Subscriptions | WebAccessify";
    }, []);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const capitalize = (text: string) => text?.charAt(0).toUpperCase() + text?.slice(1);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const columns = [
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
        },
        {
            title: 'Requested At',
            dataIndex: 'requested_at',
            key: 'requested_at',
            render: (requested_at: Date) => <Text>{new Date(requested_at).toLocaleDateString()}</Text>,
        },
        {
            title: 'Accepted At',
            dataIndex: 'accepted_at',
            key: 'accepted_at',
            render: (accepted_at: Date) => <Text>{accepted_at ? new Date(accepted_at).toLocaleDateString(): '---'}</Text>,
        },
        {
            title: 'Requested Plan',
            dataIndex: 'requested_plan',
            key: 'requested_plan',
            render: (requested_plan: { name: string }) => (
                <Text>{capitalize(requested_plan.name)}</Text>
            )
        },
        {
            title: 'Status',
            dataIndex: 'requested_plan',
            key: 'requested_plan',
            render: (requested_plan: { status: string }) => (
                <Text>{capitalize(requested_plan.status)}</Text>
            )
        },
        {
            title: 'Actual Price',
            dataIndex: 'requested_plan',
            key: 'requested_plan',
            render: (requested_plan: { actual_price: string, price_currency: string }) => (
                <Text>{requested_plan.actual_price} {requested_plan.price_currency}</Text>
            )
        },
        {
            title: 'Paid Price',
            dataIndex: 'requested_plan',
            key: 'requested_plan',
            render: (requested_plan: { discounted_price: string, price_currency: string }) => (
                <Text>{requested_plan.discounted_price} {requested_plan.price_currency}</Text>
            )
        },
        {
            title: 'Promo Code',
            dataIndex: 'requested_plan',
            key: 'requested_plan',
            render: (requested_plan: { promo: string }) => (
                <Text>{requested_plan.promo ? requested_plan.promo: '---'}</Text>
            )
        },
        {
            title: 'Off',
            dataIndex: 'requested_plan',
            key: 'requested_plan',
            render: (requested_plan: { promo_percentage: string }) => (
                <Text>{requested_plan?.promo_percentage ? `${requested_plan.promo_percentage}%` : '---'}</Text>
            )
        }
    ];

    const data = user?.plan_requests?.map((plan, index) => ({
        key: index,
        website: plan.website,
        requested_at: plan.requested_at,
        accepted_at: plan.accepted_at,
        requested_plan: plan.requested_plan,
        current_plan: plan.current_plan
    })) || [];


    return (
        <div>
            <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography.Title level={4}>Subscriptions</Typography.Title>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()} style={{ marginBottom: '10px' }}>
                    Add Subscription
                </Button>
            </Space>

            <Table columns={columns} dataSource={data} scroll={{ x: true }} />

            <Modal
                title={"Add Subscription"}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose={true}
            >
                <AddSubscription
                    onSuccess={refetch}
                    onClose={handleCancel}
                />
            </Modal>
        </div>
    );
};