import { useState, useEffect } from "react";
import { Form, Input, Button, Select, Checkbox, message } from "antd";
import { useGetIdentity } from "@refinedev/core";
import config from "config/config";

const API_BASE_URL = config.API_BASE_URL;

export const RenewSubscription = ({ onSuccess, onClose, record }: any) => {
    const [form] = Form.useForm();
    const [showPromoField, setShowPromoField] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<string>("");
    const [planPrice, setPlanPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const [promoErrorMessage, setPromoErrorMessage] = useState<string>("");
    const [promoSuccessMessage, setPromoSuccessMessage] = useState<string>("");
    const [selectedWebsite, setSelectedWebsite] = useState<string>("");

    // Handle checkbox change
    const handleCheckboxChange = (e: any) => {
        setShowPromoField(e.target.checked);
    };

    useEffect(() => {
        if (!selectedPlan) return;

        let price = 0;
        switch (selectedPlan) {
            case "basic":
                price = 14;
                break;
            case "pro":
                price = 29;
                break;
            case "ultimate":
                price = 42;
                break;
            case "custom":
                price = 0;
                break;
            default:
                price = 0;
        }
        setPlanPrice(price);
        form.setFieldsValue({ planPrice: price });
        if (promoCode) {
            verifyPromoCode(promoCode);
        }
    }, [selectedPlan, planPrice]);

    const verifyPromoCode = async (promoCode: any) => {
        try {
            setPromoCode(promoCode);
            const token = localStorage.getItem("wafy-access-token");
            const response = await fetch(`${API_BASE_URL}/user/api/v1/verify-promo`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ promoCode, planPrice }),
            });

            const data = await response.json();
            if (response.ok) {
                setPromoSuccessMessage(data.message);
                setPromoErrorMessage("");
                form.setFieldsValue({ planPrice: data.newPrice });
            } else {
                setPromoErrorMessage(data.message);
                setPromoSuccessMessage("");
            }
        } catch (error: any) {
            setPromoErrorMessage(error);
            setPromoSuccessMessage("");
        }
    };

    // Handle form submission
    const handleFormSubmit = async (values: any) => {
        try {
            setLoading(true);
            const token = localStorage.getItem("wafy-access-token");

            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/add-subscription`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                }
            );

            const data = await response.json();
            if (response.ok) {
                message.success(data.message || "Site added successfully!");
                onSuccess();
                onClose();
            } else {
                message.error(data.message || "Failed to add site");
            }
        } catch (error) {
            message.error("Error occurred while adding site");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ borderRadius: '15px', width: '100%', backgroundColor: '#fcfcfc', padding: '20px' }}>
            <Form
                form={form}
                onFinish={handleFormSubmit}
                style={{ marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
                {/* Selected Website */}
                <Form.Item
                    label="Website"
                    name="siteUrl"
                    initialValue={record.url}
                    rules={[
                        { required: true, message: 'Please enter Website!' },
                        {
                            pattern: /^(?!-)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
                            message: 'Please enter a valid domain (e.g., example.com or sub.example.com)',
                        }
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                {/* Select Subscription Plan */}
                <Form.Item
                    label="Select Subscription Plan"
                    name="subscriptionPlan"
                    rules={[{ required: true, message: 'Please select a subscription plan!' }]}
                >
                    <Select placeholder="Choose a plan" onChange={setSelectedPlan}>
                        <Select.Option value="basic">Basic</Select.Option>
                        <Select.Option value="pro">Pro</Select.Option>
                        <Select.Option value="ultimate">Ultimate</Select.Option>
                        <Select.Option value="custom">Custom</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Plan Price" name="planPrice">
                    <Input
                        value={planPrice}
                        readOnly
                    />
                </Form.Item>

                {/* Checkbox to Show Promo Code */}
                <Form.Item name="promoCheckbox" valuePropName="checked">
                    <Checkbox onChange={handleCheckboxChange}>
                        Do you want to apply promo code?
                    </Checkbox>
                </Form.Item>

                {/* Promo Code Input - Conditionally Rendered */}
                {showPromoField && (
                    <Form.Item label="Enter Promo Code" name="promoCode">
                        <div style={{ display: 'flex' }}>
                            <Input placeholder="PROMO123" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} style={{ flex: 1 }} />
                            <Button type="primary" onClick={() => verifyPromoCode(promoCode)} style={{ marginLeft: 8 }}>
                                Apply Promo
                            </Button>
                        </div>
                    </Form.Item>
                )}

                {/* Display Promo Code Error */}
                {promoSuccessMessage && (
                    <p style={{ color: 'green', fontSize: '12px' }}>{promoSuccessMessage}</p>
                )}

                {/* Display Promo Code Error */}
                {promoErrorMessage && (
                    <p style={{ color: 'red', fontSize: '12px' }}>{promoErrorMessage}</p>
                )}

                {/* Submit Button */}
                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        <Button onClick={() => onClose()}>
                            Close
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};
