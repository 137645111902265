import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { Snippet } from '../code-snippet';
import { useGetIdentity, useOne } from "@refinedev/core";
import { DownOutlined, RightOutlined } from '@ant-design/icons';

type SnippetInterface = {
    code: string,
}

export const Installation = () => {
    const [faqOpen, setFaqOpen] = useState<string | null>(null);
    // Getting client key
    const { data: user } = useGetIdentity<{
        user_websites: { selected: string, clientKey: string }[]
    }>();

    useEffect(() => {
        document.title = "Installation | WebAccessify";
    }, []);

    // Getting the client key of the selected website
    const clientKey = user?.user_websites?.find((website) => website.selected === 'true')?.clientKey;

    const code = `<script>!function(){var e=document.createElement("script");e.src="https://manage.webaccessify.com/wafy/widget.js?clientKey=${clientKey}",document.head.appendChild(e)}();</script>`;

    const faqs = [
        {
            question: "How to install WebAccessify plugin in WordPress website?",
            answer: [
                "Log in to your WordPress admin dashboard.",
                "Go to 'Appearance' > 'Theme Editor'.",
                "In the right-hand menu, locate and click on 'header.php'.",
                "Paste the provided script inside the `<head>` tag.",
                "Save the changes and refresh your website to verify the installation.",
            ],
        },
        {
            question: "How to add the WebAccessify script in a React website?",
            answer: [
                "Open the root `index.html` file in the `public` folder.",
                "Locate the `<head>` section in the file.",
                "Paste the provided script inside the `<head>` tag.",
                "Save the file and restart your development server.",
            ],
        },
        {
            question: "How to write the WebAccessify script in an Angular website?",
            answer: [
                "Locate the `index.html` file inside the `src` folder of your Angular project.",
                "Find the `<head>` section in the file.",
                "Paste the provided script inside the `<head>` tag.",
                "Save the file and run the Angular application.",
            ],
        },
        {
            question: "How to add the WebAccessify script in a Joomla website?",
            answer: [
                "Log in to your Joomla admin panel.",
                "Go to 'Extensions' > 'Templates' > 'Templates'.",
                "Select your active template and click on 'Edit'.",
                "Navigate to the 'index.php' file and locate the `<head>` section.",
                "Paste the provided script inside the `<head>` tag.",
                "Save the file and refresh your website.",
            ],
        },
        {
            question: "How to add the WebAccessify script in a Shopify website?",
            answer: [
                "Log in to your Shopify admin dashboard.",
                "Navigate to 'Online Store' > 'Themes'.",
                "Click on 'Actions' > 'Edit Code' for your active theme.",
                "Open the 'theme.liquid' file and locate the `<head>` section.",
                "Paste the provided script inside the `<head>` tag.",
                "Save the changes and preview your store.",
            ],
        },
        {
            question: "How to add the WebAccessify script in a Wix website?",
            answer: [
                "Log in to your Wix account.",
                "Go to 'Settings' > 'Tracking & Analytics'.",
                "Click on 'New Tool' > 'Custom' and paste the provided script.",
                "Choose 'Head' as the location and click 'Apply'.",
                "Publish your website to apply the changes.",
            ],
        },
        {
            question: "How to add the WebAccessify script in a Squarespace website?",
            answer: [
                "Log in to your Squarespace account.",
                "Go to 'Settings' > 'Advanced' > 'Code Injection'.",
                "Paste the provided script into the 'Header' section.",
                "Save the changes and refresh your website.",
            ],
        },
        {
            question: "How to add the WebAccessify script in a Drupal website?",
            answer: [
                "Log in to your Drupal admin dashboard.",
                "Go to 'Structure' > 'Block Layout'.",
                "Click on 'Add Custom Block' and paste the provided script in the content section.",
                "Place the block in the 'Header' region and save the changes.",
                "Clear the cache to apply the updates.",
            ],
        },
        {
            question: "How to add the WebAccessify script in a plain HTML website?",
            answer: [
                "Open the HTML file of your website.",
                "Locate the `<head>` section in the file.",
                "Paste the provided script inside the `<head>` tag.",
                "Save the file and open your website in a browser to verify the changes.",
            ],
        },
        {
            question: "How to add the WebAccessify script in a Laravel project?",
            answer: [
                "Locate the `resources/views/layouts` directory.",
                "Open the `app.blade.php` file (or your primary layout file).",
                "Find the `<head>` section in the file.",
                "Paste the provided script inside the `<head>` tag.",
                "Save the file and test your Laravel application.",
            ],
        },
        {
            question: "What if my website platform is not listed here?",
            answer: [
                "If your platform is not listed, you can still integrate WebAccessify by adding the script inside the `<head>` section of your website's main HTML file.",
                "If you need further assistance, feel free to contact our support team.",
                'Reach out to us at <a href="mailto:support@webaccessify.com">support@webaccessify.com</a> or visit our <a href="https://webaccessify.com/contact-us/">Contact Us</a> page.'
            ],
        }
    ];


    return (
        <div style={{ margin: '0 auto' }}>
            <div className='card' style={{ backgroundColor: '#fcfcfc', borderRadius: '15px' }}>
                <Col>
                    <Row>
                        <h2 style={{ padding: '10px 20px', color: '#000' }}> Widget Embedded Script</h2>
                    </Row >
                    <Row>
                        <div
                            style={{
                                backgroundColor: '#d0eeed',
                                margin: '2%',
                                borderRadius: '10px',
                                padding: '20px',
                                color: '#000',
                                width: "100%",
                                maxHeight: '200px',
                                overflowY: 'auto',
                            }}
                            id="colored-text"
                        ><Snippet code={code} />
                        </div>
                    </Row>
                </Col>
            </div>

            {/* FAQ Section */}
            <div
                className="card"
                style={{
                    backgroundColor: '#fcfcfc',
                    borderRadius: '15px',
                    marginTop: '20px',
                    padding: '20px',
                }}
            >
                <h2 style={{ color: '#000' }}>Installation Guides</h2>
                {faqs.map((faq, index) => (
                    <div
                        key={index}
                        style={{
                            marginBottom: '10px',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px',
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                        }}
                        onClick={() => setFaqOpen(faqOpen === faq.question ? null : faq.question)}
                    >
                        <Row justify="space-between" align="middle">
                            <Col>
                                <h3 style={{ color: '#012970', margin: 0, display: 'flex', alignItems: 'center' }}>
                                    {faqOpen === faq.question ? (
                                        <DownOutlined style={{ marginRight: '10px', color: '#012970' }} />
                                    ) : (
                                        <RightOutlined style={{ marginRight: '10px', color: '#012970' }} />
                                    )}
                                    {faq.question}
                                </h3>
                            </Col>
                        </Row>
                        {faqOpen === faq.question && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    paddingLeft: '10px',
                                    color: '#333',
                                    borderTop: '1px solid #eee',
                                    paddingTop: '10px',
                                }}
                            >
                                <ul>
                                    {faq.answer.map((step, idx) => (
                                        <li key={idx} style={{ marginBottom: '5px' }} dangerouslySetInnerHTML={{ __html: step }} />
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
};
