import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Card } from 'antd';
import { useGetIdentity } from "@refinedev/core";

const { Title, Text } = Typography;

export const Home = () => {
  const { data: user } = useGetIdentity<{
    user_websites: {
      url: string;
      selected: string;
      widget_status: string;
      vision_impaired_profile: string;
      blind_profile: string;
      dyslexic_profile: string;
      cognitive_impaired_profile: string;
      adhd_profile: string;
      motor_impaired_profile: string;
      screen_reader_status: string;
      voice_nav_status: string;
      dark_contrast_status: string;
      high_contrast_status: string;
      low_contrast_status: string;
      monochrome_status: string;
      high_saturation_status: string;
      low_saturation_status: string;
      font_size_status: string;
      dyslexia_font_status: string;
      left_align_status: string;
      right_align_status: string;
      center_align_status: string;
      justify_align_status: string;
      line_height_status: string;
      text_spacing_status: string;
      highlight_links_status: string;
      highlight_headings_status: string;
      pause_animations_status: string;
      hide_images_status: string;
      white_cursor_status: string;
      black_cursor_status: string;
      keyboard_nav_status: string;
      highlight_hover_status: string;
      reading_mask_status: string;
      reading_guide_status: string;
    }[];
    plan_requests: {
      _id: string;
    }[];
    mutate: () => void;
  }>();

  const [totalWebsites, setTotalWebsites] = useState(0);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [activeFeatures, setActiveFeatures] = useState(0);

  useEffect(() => {
    document.title = "Dashboard | WebAccessify";

    if (user) {
      setTotalWebsites(user?.user_websites.length || 0);
      setTotalSubscriptions(user?.plan_requests.length || 0);

      const selectedWebsite = user?.user_websites?.find((website) => website.selected === 'true');

      if (selectedWebsite) {
        // Count how many accessibility features are active
        const activeFeatureCount = Object.entries(selectedWebsite).filter(([key, value]) => key !== "license_status" && value === "active").length;
        setActiveFeatures(activeFeatureCount);
      } else {
        setActiveFeatures(0);
      }
    }
  }, [user]);

  return (
    <div>
      <Title level={2} style={{ fontWeight: 700 }}>
        Dashboard
      </Title>

      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card style={{ textAlign: 'center', padding: '20px' }}>
            <Title level={4}>Total Websites</Title>
            <Text strong>{totalWebsites}</Text>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card style={{ textAlign: 'center', padding: '20px' }}>
            <Title level={4}>Total Subscriptions</Title>
            <Text strong>{totalSubscriptions}</Text>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card style={{ textAlign: 'center', padding: '20px' }}>
            <Title level={4}>Active Features</Title>
            <Text strong>{activeFeatures}</Text>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
